<template>
<div :class="'tab-pane py-2 py-xl-2 fade show '+this.active " id="ingresados" role="tabpanel" aria-labelledby="ingresados-tab">
    <div class="row justify-content-center">
        <Spinner />
        <div class="container-xl px-4 mt-n10">
            <div class="card-body">
                <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <input id="filter-input-gestiones" class="form-control w-50" placeholder="Filtrar gestiones" />
                        </div>
                        <div class="col-md-6">
                            <div class="dataTable-top dataTable-top justify-content-md-end justify-content-center" style="display: flex;">
                                <button style="width:220px;margin-bottom:10px;" class="btn btn-primary lift lift-sm btn-alta" type="button" @click="redirectAltaGenerico">Nueva Gesti&oacute;n <i class="fas fa-plus" style="margin-left: 5px;"></i></button>
                            </div>

                        </div>
                    </div>

                    <div class="dataTable-container" style="height:500px;overflow-y:auto;">
                        <table id="datatablesSimple" class="dataTable-table table-mobile">
                            <thead>
                                <tr>
                                    <th data-sortable="" style="width: 12,5%;" class="th-head-tramite"><a href="#" class="dataTable-sorter">Gesti&oacute;n ID#</a></th>
                                    <!-- <th data-sortable="" style="width: 12,5%;" class="th-head-tramite"><a href="#" class="dataTable-sorter">Usuario</a></th> -->
                                    <th data-sortable="" style="width: 12,5%;" class="th-head-tramite"><a href="#" class="dataTable-sorter">Tipo Gesti&oacute;n</a></th>
                                    <!-- <th data-sortable="" style="width: 12,5%;" class=""><a href="#" class="dataTable-sorter">T&iacute;tulo</a></th> -->
                                    <th data-sortable="" style="width: 12,5%;" class="th-head-tramite"><a href="#" class="dataTable-sorter">Fecha</a></th>
                                    <!-- <th data-sortable="" style="width: 12,5%;" class="th-head-tramite"><a href="#" class="dataTable-sorter">Fecha Modificaci&oacute;n</a></th> -->
                                    <th data-sortable="" style="width: 12,5%;" class="th-head-tramite"><a href="#" class="dataTable-sorter">Estado</a></th>
                                    <th data-sortable="" style="width: 12,5%;" class="th-head-tramite"><a href="#" class="dataTable-sorter">Acciones</a></th>
                                </tr>
                            </thead>
                            <tbody id="tbody-gestiones" v-if="this.errorListaTramite == null">
                                <tr class="tr-background" v-for="(tramite,index) in this.tramitesIngresados" :key="index" v-if="tramite.tipo == 'GESTION'">
                                    <td class="text-end text-md-center" data-label="ID">{{tramite.id}}</td>
                                    <!-- <td class="text-end text-md-start" data-label="Persona">{{primeraLetraMayus(tramite.apellido)}} {{primeraLetraMayus(tramite.nombre)}}</td> -->
                                    <td class="text-end text-md-start" data-label="Tipo Gestión">{{primeraLetraMayus(tramite.descripcion)}}</td>
                                    <!-- <td data-label="Titulo">{{primeraLetraMayus(tramite.titulo)}}</td> -->
                                    <td class="text-end text-md-center" data-label="Fecha">{{formateDate(tramite.fecha_alta)}}</td>
                                    <!-- <td class="text-end text-md-center" data-label="Fecha Modificación">{{formateDate(tramite.fecha_modificacion)}}</td> -->
                                    <td class="text-end text-md-center" data-label="Estado">
                                        {{primeraLetraMayus(tramite.estado)}}
                                    </td>
                                    <td class="text-end text-md-center" data-label="Acciones">
                                        <button class="btn btn-datatable btn-icon btn-transparent-dark me-2" type="button" @click="openModalData(tramite.id)"><i class="fas fa-eye" style="font-size:16px;"></i></button>
                                        <ModalDetalle :id="'modalDetalle_'+tramite.id" :info="tramite"></ModalDetalle>
                                        <!-- btn descargar archivos -->
                                        <button @click="openModalFile(tramite.id)" class="ms-3 btn btn-datatable btn-icon btn-transparent-dark me-2" type="button"><i class="fas fa-file-download" style="font-size:16px;"></i></button>
                                        <ModalDetalleFiles :id="'modalFile_'+tramite.id" :info="tramite" />
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <span style="color:#f4a100" v-if="this.errorListaTramite != ''">{{errorListaTramite}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
@media (max-width: 600px) {
    #filter-input-gestiones {
        width: 100% !important;
    }
}

.th-head-tramite {
    font-size: 15px;
    vertical-align: middle;
    text-align: center !important;
}

.title {
    color: black !important;
    cursor: auto;
}

.color-succes {
    background-color: green !important;
    cursor: pointer;
}

.card-body {
    margin-top: 30px;
}

.acciones-td {
    font-size: 20px;
    width: 100% !important;
}

.color-pendiente {
    background-color: #d1d102 !important;
    cursor: pointer;
}

.btn-alta-proveedor {
    color: #fff;
    /* background: linear-gradient(135deg, #22ca4e 0%, rgb(15 162 39 / 80%) 100%) !important; */
    background: #52b69a !important;
    border: none;
}

th {
    background-color: #52b69a !important;
    color: white;
}

.tr-background {
    background: white !important;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import UtilJS from '../../../../assets/js/util'
import ModalDetalle from '../misGestiones/detalleIngresado'
import ModalDetalleFiles from '../misGestiones/modalFiles'

import Spinner from '../../../spinner'
import $ from 'jquery'

export default {
    mixins: [UtilJS],
    components: {
        ModalDetalle,
        ModalDetalleFiles
    },
    mounted: function () {
        this.$store.commit("SET_BLOQUEADO", false);
        this.listTramites();

        var $rows = $('#tbody-gestiones tr');
        $('#filter-input-gestiones').keyup(function () {

            var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

            $rows.show().filter(function () {
                var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
                return !text.includes(val);
            }).hide();
        });

    },
    methods: {
        openModalFile(tramiteID) {
            this.$store.dispatch("tramiteGenerico/getFileByTramiteID", tramiteID).then((response) => {
                
                this.$store.commit('tramiteGenerico/SET_FILE_BY_TRAMITE_ID', response);
                var myModal = new bootstrap.Modal(document.getElementById('modalFile_' + tramiteID))
                myModal.show();
            }).catch((error) => {
                Vue.swal("Portal de Proveedoras/es", error.response.data, "error")
            })
        },

        openModalData(tramiteID) {
            this.$store.dispatch("tramiteGenerico/getDetalleByTramiteID", tramiteID).then((response) => {
                
                this.$store.commit('tramiteGenerico/SET_DATA_BY_TRAMITE_ID', response);
                var myModal = new bootstrap.Modal(document.getElementById('modalDetalle_' + tramiteID))
                myModal.show();
            }).catch((error) => {
                Vue.swal("Portal de Proveedoras/es", error.response.data, "error")
            })
        },

        retomarTramite(tramiteID, portal_strategy) {
            this.$store.dispatch("tramiteGenerico/getTramiteEstados", this.getDataTramite(tramiteID, portal_strategy));
        },
        getDataTramite(tramiteID, portal_strategy) {
            let obj = new Object;
            obj.id = tramiteID;
            obj.cart_usuario_id = this.user;
            obj.url = portal_strategy;
            obj.cart_empresa_id = this.$store.state.user.user.empresa.id
            return obj;
        },
        async listTramites() {
            await this.$store.dispatch("tramiteGenerico/getRegistros");
        },
        redirectTimeLine() {
            router.push("/proveedoresHistorialIngresado")
        },
        redirectAltaGenerico() {
            this.resaltarSeleccionado();
            router.push("/ingresoGenerico")
        },
        resaltarSeleccionado() {
            this.$store.commit("menu/SET_ACTIVO", "" + "ingresoGenerico");
        }

    },
    computed: {
        tramitesIngresados: {
            get() {

                return this.$store.state.tramiteGenerico.tramitesIngresados;
            }
        },
        errorListaTramite: {
            get() {
                return this.$store.state.tramiteGenerico.errorListaTramite;
            }
        },
        user: {
            get() {
                return this.$store.state.user.user.id;
            },
        },
    },
    props: {
        active: String,
    }
}
</script>>
