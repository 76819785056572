<template>
<div class="modal fade" :id="this.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <Spinner />
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                    <h3>Archivos #{{info.titulo}} (#{{info.id}})</h3>
                </h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-md-center" v-if="archivosList.length == 0">
                    <h6> No se encontraron archivos.</h6>
                </div>
                <div class="row justify-content-md-center" v-else>
                    <table class="dataTable-table table-striped table-mobile">
                        <thead>
                            <th class="text-center" style="width: 10%;">#ID</th>
                            <th class="text-center" style="width: 30%;">Nombre</th>
                            <th class="text-center">Fecha Alta</th>
                            <th class="text-center" style="width: 30%;">Descripci&oacute;n</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr class="tr-background" v-for="(archivo,index) in this.archivosList" :key="index">
                                <td class="text-end text-md-center" data-label="ID">{{archivo.id}}</td>
                                <td class="text-end text-md-center" data-label="Nombre">{{archivo.nombre_archivo}}</td>
                                <td class="text-end text-md-center" data-label="Fecha">{{archivo.fecha_alta}}</td>
                                <td class="text-end text-md-center" data-label="Descripción">{{archivo.descripcion}}</td>
                                <td class="text-end text-md-center" data-label="" style="cursor:pointer" @click="descargarArchivoAdjunto(archivo.key,archivo.nombre_archivo)"><i class="fas fa-download"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="modal-footer"><button class="btn" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal">Cerrar</button></div>
        </div>
    </div>
</div>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}

.estado_finalizado_aceptado {
    background: green;
    font-size: 11px;
    font-weight: 600;
    color: white;
    padding: 4px;
    border-radius: 10px;
}

.estado_finalizado_pendiente {
    background: orange;
    font-size: 11px;
    font-weight: 600;
    color: white;
    padding: 4px;
    border-radius: 10px;
}

.estado_error {
    background: #f55959;
    font-size: 11px;
    font-weight: 600;
    color: white;
    padding: 4px;
    border-radius: 10px;
}

.title-id {
    font-size: 14px !important
}

.content-id {
    font-weight: 600;
    font-size: 14px;
}
</style>

<script>
import $ from "jquery";
import router from '@/router/index.js' // Vue router instance
import UtilJS from '../../../../assets/js/util'
import Spinner from '../../../spinner'
import Vue from 'vue'



export default {
    mixins: [UtilJS],
    components: {},
    mounted: function () {},
    methods: {
        getEstado(estado) {
            return estado;
        },
        async descargarArchivoAdjunto(key,nombre) {
            try {
                const uploadResult = await this.$store.dispatch("tramiteGenerico/downloadFileS3", {fileName:nombre, key:key});
                const blob = new Blob([uploadResult.data],{ type: 'application/octet-stream' });
                const fileName = nombre;
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', fileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            } catch (error) {
                return Vue.swal("Portal Proveedores", error.message, "info");
            }
        }
    },
    computed: {
        archivosList: {
            get() {
                return this.$store.state.tramiteGenerico.filesByTramite;
            }
        },
    },
    props: {
        id: String,
        info: Object,
    }
}
</script>
