<template>
<div>
    <!-- navbar -->
    <Navbar />
    <div id="layoutSidenav">
        <Menu />
        <spinner></spinner>
        <div id="layoutSidenav_content">
            <main>
                 <!-- header -->
                 <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="fas fa-folder"></i></div>
                                            Mis Gestiones
                                        </h1>
                                        <div class="page-header-subtitle">Consult&aacute; las gestiones realizadas</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
            <!-- fin header -->
            <!-- body -->
                <div class="container-xl px-4 mt-n10">
                        <!-- Wizard card navigation-->
                        <div class="card mb-5">
                            <div class="card-header border-bottom">
                                <!-- Wizard navigation-->
                                <div class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                                    <!-- Wizard navigation item 1-->
                                    <CardButton title="Detalle de gestiones" active="active" subTitle="" id="ingresados" :numberCard="tramitesIngresados"/> 
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="tab-content" id="cardTabContent">
                                    <!-- Wizar item 1-->
                                    <Ingresado active="active"/>
                                </div>
                            </div>
                        </div>
                    </div>
            <!-- fin body -->
            </main>
        </div>
    </div>

</div>
</template>

<style>
.btn-alta {
   color: #fff;
    background-color:#002f87 !important;
    border:none;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import spinner from '../../../components/spinner'
import Vue from 'vue'
import Navbar from '../../../components/home/navbar/navbar'
import Menu from '../../../components/home/menu'
import CardButton from '../../../components/home/step/util/cardButton'
import Ingresado from '../../../components/home/step/misGestiones/misGestiones'
import CardTramite from '../../../components/home/step/util/cardTramites'
export default {
    name: "Home",
    components: {
        spinner,
        Navbar,
        Menu,
        CardButton,
        Ingresado,
        CardTramite
    },
    mounted: function () {
        this.addMenu();
    },
    methods: {
        addMenu() {
            document.body.classList.remove("sidenav-toggled");
        }
    },
    computed: {
        tramitesIngresados: {
            get() {

                return this.$store.state.tramiteGenerico.tramitesIngresados? this.$store.state.tramiteGenerico.tramitesIngresados.length : 0;
            }
        },
    },
};
</script>
