<template>
<div class="modal fade" :id="this.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                    <h3>Detalle {{info.titulo}} (#{{info.id}})</h3>
                </h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-md-center">
                    <div class="col-md-12" v-for="(data,index) in this.dataList" :key="index">
                        <h3 class="title-id text-start">{{data.descripcion}}</h3>
                        <hr class="mb-1 mt-1">
                        <div class="row mb-5 mt-1">
                            <div class="col-md-12 text-center" v-for="(atributos,index) in data.atributos" :key="index">
                                <div class="row mt-2">
                                    <div class="col-md-6 text-end ps-0 pe-1">{{atributos.portal_leyenda}} :</div>
                                    <div class="col-md-6 text-start ps-0" v-if="atributos.descripcion_select == null"> {{getJson(atributos.valor)}}</div>
                                    <div class="col-md-6 text-start ps-0" v-else> {{getJson(atributos.descripcion_select)}}</div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer"><button class="btn" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal">Cerrar</button></div>
        </div>
    </div>
</div>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}

.estado_finalizado_aceptado {
    background: green;
    font-size: 11px;
    font-weight: 600;
    color: white;
    padding: 4px;
    border-radius: 10px;
}

.estado_finalizado_pendiente {
    background: orange;
    font-size: 11px;
    font-weight: 600;
    color: white;
    padding: 4px;
    border-radius: 10px;
}

.estado_error {
    background: #f55959;
    font-size: 11px;
    font-weight: 600;
    color: white;
    padding: 4px;
    border-radius: 10px;
}

.title-id {
    font-size: 14px !important
}

.content-id {
    font-weight: 600;
    font-size: 14px;
}
</style>

<script>
import $ from "jquery";
import router from '@/router/index.js' // Vue router instance
import UtilJS from '../../../../assets/js/util'

export default {
    mixins: [UtilJS],
    components: {},
    mounted: function () {},
    methods: {
        getEstado(estado) {
            return estado;
        },
        getJson(data){
            try {
                
                 if(data != null && (data.includes("[") || data.includes("{")) ){
                     return  '-';
                 }
                 return data;
            } catch (error) {
                return '-';
            }
        }
    },
    computed: {
        dataList: {
            get() {
                debugger;
                return this.$store.state.tramiteGenerico.dataByTramite;
            }
        },
    },
    props: {
        id: String,
        info: Object,
    }
}
</script>
